// Instagram Feed
.c-instagram-feed {
    padding-top: 80px;
    padding-bottom: calc(80px + 35px);

	@media(min-width: 768px) {
		padding-top: 100px;
		padding-bottom: calc(100px + 35px);
	}
}

.c-instagram-feed #sb_instagram,
.c-instagram-feed .sbi_carousel {
    padding: 0!important;
}

.c-instagram-feed .sbi-owl-nav>div, 
.c-instagram-feed .sbi_carousel:hover .sbi-owl-nav>div:hover, 
.c-instagram-feed .sbi_carousel:hover .sbi-owl-nav>div {
    background-color colorRed;
    bottom: 0;
    opacity 1;
    border-radius: 0;
    top: unset!important;
    height: 70px;
    width: 70px;
    transform: translateY(50%);
}

.c-instagram-feed .sbi-owl-nav .sbi-owl-prev {
    left: 100px;
}

.c-instagram-feed .sbi-owl-nav .sbi-owl-next {
    right: 100px;
}

.c-instagram-feed .sbi-owl-nav svg path {
    fill: #ffffff;
}

.c-instagram-feed .sbi-owl-nav svg {
    height: 100%!important;
}

.c-instagram-feed #sbi_load, 
.c-instagram-feed .sb_instagram_header {
    display none
}

.c-instagram-feed > .container > div {
    display flex
    flex-direction: column;
    justify-content: space-between;

    @media(min-width: 768px) {
        flex-direction: row;
    }
}

.c-instagram-feed__content {
    margin-bottom 20px
}

.c-instagram-feed__content h2 {
    margin-top 0;
    margin-bottom 15px
}

.c-instagram-feed__content p {
    color: #fff;
}

.c-instagram-feed__button a  {
    display inline-block
    margin-bottom: 30px;

    @media(min-width: 768px) {
        margin-bottom 0
    }
}


.c-btn {
    background colorRed
    color: #ffffff;
    text-transform: uppercase
    font-weight: bold;
    font-size: 25px;
    text-decoration none    
    padding: 10px 20px;
}