* {-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.container {
    // padding-right: (gutterWidth / 2);
    // padding-left: (gutterWidth / 2);
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
.container-fluid {
    // padding-right: (gutterWidth / 2);
    // padding-left: (gutterWidth / 2);
    margin-right: auto;
    margin-left: auto;
}
.row {
    // margin-right: (gutterWidth / -2);
    // margin-left: (gutterWidth / -2);
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    display: table;
    content: " ";
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}


.pull-left {
    float: left !important;
}
.pull-right {
    float: right !important;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}
.text-nowrap {
    white-space: nowrap;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}
