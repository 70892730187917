div.music {
	position: relative
	&:before,
	&:after {
		content: " "
		display: table
	}
	&:after {
		clear: both
	}
}

.music__sizer {
	width: 10%
	@media (max-width: 800px) {
		width: 25%
	}
}

.music-item {
	float: left
	width: 20%
	display: block
	position: relative
	background-size: cover
	background-position: center center
	&:before {
		content: ""
		display: block
		width: 100%
		padding-top: 100%
	}
	@media (max-width: 800px) {
		width: 25%
	}
	@media (max-width: 600px) {
		width: 50%
	}
}
.music-item > div {
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
}

.music__stamp {
	width: 30%
	position: absolute
	top: 0
	&:before {
		content: ""
		display: block
		width: 100%
		padding-top: 133.3333333%
		@media (max-width: 630px) {
			content: none
		}
	}
	> div {
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
	}
	@media (max-width: 1100px) {
		width: 40%
	}
	@media (max-width: 800px) {
		width: 50%
	}
	@media (max-width: 630px) {
		width: 100%
	}
}

/*------------------------------*\
	Player
\*------------------------------*/
.music__player {
	left: 20%
	z-index: 1
	@media (max-width: 800px) {
		left: 0
	}
	@media (max-width: 630px) {
		height: 175px
	}
}
.music__player > div {
	background: colorRed url('../images/cls/blue-wood.jpg') top left repeat
}

.music__vinyl {
	position: absolute
	width: 75%
	top: 20px
	left: 5%
	animation: rotate 10s linear infinite
	@media (max-width: 630px) {
		display: none
	}
}
.music__vinyl--paused {
	-webkit-animation-play-state: paused
	animation-play-state: paused
}
.music__vinyl--active {
	-webkit-animation-play-state: running
	animation-play-state: running
}
@keyframes rotate {
	from {
		transform: rotate(0)
	}
	to {
		transform: rotate(360deg)
	}
}


.music__needle {
	position: absolute
	width: 23%
	top: 25px
	right 5%
	transform-origin: 62% 15%
	transition: transform 0.8s
	@media (max-width: 630px) {
		display: none
	}
}
.music__needle--active {
	transform: rotate(16deg)
}

.music-controls {
	position: absolute
	bottom: 60px
	left: 0
	width: 100%
	text-align: center
	@media (max-width: 630px) {
		top: 20px
		bottom: auto
	}
}
.music-control {
	font-size: 30px
	display: inline-block
	width: 25%
	text-align: center
	vertical-align: middle
}
.music-control button {
	-webkit-appearance: none
	appearance: none
	border-radius: 50%
	box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.4)
}
.music-control--big button {
	padding: 0
	background: black
	border: 2px solid white
	width: 64px
	height: 64px
	line-height: 20px
}
.music-control--small button {
	padding: 0
	background: colorBlue
	border: 0
	width: 40px
	height: 40px
	font-size: 20px
	line-height: 48px
	display: block
	margin-left: auto
	margin-right: auto
}

.music__prev {
	text-indent: -3px
	margin-top: 20px
}

/*------------------------------*\
	Playlist
\*------------------------------*/
.music__playlist {
	left: 50%
	text-transform: uppercase
	z-index: 1
	@media (max-width: 1100px) {
		left: auto
		right: 0
	}
	@media (max-width: 630px) {
		top: 175px
		width: 100%
		height: 300px
	}
}
.music__playlist > div {
	overflow: auto
	&::-webkit-scrollbar {
		width: 7px
	}
	&::-webkit-scrollbar-track {
		background: colorBlue
	}
	&::-webkit-scrollbar-thumb {
		background: black
	}
}

.music__playlist ul {
	list-style: none
	margin: 0
	padding: 0
}
.music__playlist li {
	margin: 0
	padding: 0
	background-color: #2D2D2D
	&:nth-child(2n+1) {
		background-color: #1A1A1A
	}
}
.music__playlist a {
	display: block
	padding: 10px 12px
	color: inherit
	text-decoration: none
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis;
}

.music-track--active {
	background: colorRed
}



.music__spotify {
	position: absolute
	bottom: 20px
	left: 50%
	margin-left: -80px
}

.open-music-link {
	padding: 0;
	background-color: #000;
	border: 2px solid #fff;
	width: 64px;
	background-size 50%
	height: 64px;
	line-height: 20px;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor pointer;
	appearance: none;
	border-radius: 50%;
	box-shadow: -2px 2px 0px 0px rgba(0,0,0,0.4);
}
