.social-links {
	list-style: none
	margin: 0 0 10px
	padding: 0
	text-align: center
	font-size: 30px
	line-height: 1
	@media (max-width: 660px) {
		font-size: 18px
	}
}

.social-links li {
	margin: 0 15px 15px
	padding: 0
	display: inline-block
}

.social-links a {
	color: white
	text-decoration: none
}

.social-links .icon {
	vertical-align: -0.1em
	margin-right: 10px
}



div.social {
	font-size: 18px
	line-height: 1.2
	&:before,
	&:after {
		content: " "
		display: table
	}
	&:after {
		clear: both
	}
}

.social-item {
	float: left
	width: 20%
	display: block
	position: relative
	color: white
	text-decoration: none
	background-size: cover
	background-position: center center
	word-wrap: break-word
	&:before {
		content: ""
		display: block
		width: 100%
		padding-top: 100%
	}
	@media (max-width: 1900px) {
		width: 25%
	}
	@media (max-width: 1450px) {
		width: 33.3333333%
	}
	@media (max-width: 939px) {
		width: 50%
	}
}

.social-item p {
	@media ( max-width: 670px ) {
		font-size: 18px
	}
	@media ( max-width: 400px ) {
		font-size: 14px
		margin: 0
	}

	@media ( max-width: 340px ) {
		font-size: 12px
		margin: 0
	}
}

.social-item > div {
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	padding: 80px 40px
	font-family: agendaFont
	font-weight: 300
	display: flex
	align-items: center
	justify-content: center
	text-align: center
	&:before {
		position: absolute
		top: 25px
		right: 25px
		font-size: 29px
		@media (max-width: 939px) {
			top: 8px
			right: 8px
		}
		@media (max-width: 450px) {
			font-size: 22px
			top: 2px
			right: 2px
		}
	}
	@media (max-width: 939px) {
		padding: 20px
	}
	@media (max-width: 600px) {
		padding: 10px
	}
	@media (max-width: 400px) {
		padding: 5px
	}
}
.social-item--image > div {
	background-color: transparent
}

.social-item__date {
	font-size: 24px
	position: absolute
	left: 40px
	font-weight: 700
	bottom: 40px
	@media (min-width: 940px) and (max-width: 1200px) {
		font-size: 18px
	}
	@media (max-width: 939px) {
		bottom: 10px
	}
	@media(max-width: 568px) {
		font-size: 16px;
		bottom: 5px
		left: 50%
		transform: translateX(-50%)
		font-size: 14px
		width: 100%
	}
}

.social-twitter-block {
	h2 {
		font-weight: 400
		font-size: 50px
		margin: 0
		@media ( max-width: 1040px ) {
			font-size: 44px
		}
		@media ( max-width: 450px ) {
			font-size: 24px
		}
	}
	span {
		font-weight: 700
		font-size: 80px
		display: block
		text-transform: initial
		@media ( max-width: 1040px ) {
			font-size: 38px
		}
		@media ( max-width: 450px ) {
			font-size: 20px
		}
	}
}

.social-facebook-block {
	h2 {
		font-weight: 700
		font-size: 80px
		text-transform: initial
		margin: 0
		@media ( max-width: 1040px ) {
			font-size: 44px
		}
		@media ( max-width: 450px ) {
			font-size: 24px
		}
	}
	span {
		font-weight: 400
		font-size: 50px
		display: block
		text-transform: uppercase
		@media ( max-width: 1040px ) {
			font-size: 38px
		}
		@media ( max-width: 450px ) {
			font-size: 20px
		}
	}
}

.social-instagram-block {
	background-color: white
	border: 6px solid colorYellow
	h2 {
		font-weight: 400
		font-size: 70px
		text-transform: initial
		color: colorRed
		margin: 0
		@media ( max-width: 1040px ) {
			font-size: 44px
		}
		@media ( max-width: 450px ) {
			font-size: 24px
		}
	}
	span {
		font-weight: 400
		font-size: 70px
		display: block
		text-transform: uppercase
		color: colorBlue
		@media ( max-width: 1040px ) {
			font-size: 38px
		}
		@media ( max-width: 450px ) {
			font-size: 20px
		}
	}
}



.social-item--red img,
.social-item--blue img,
.social-item--cream img,
.social-item--red svg,
.social-item--blue svg,
.social-item--cream svg {
	position: absolute
	top: 50%
	left: 50%
	width: calc(100% - 60px)
	transform: translateX(-50%) translateY(-50%)
}




.social--footer .social-item {
	width: 25%;
	height: auto;

	@media (max-width: 939px) {
		width: 50%
	}

	@media(max-width: 767px) {
		height: 190px;
	}

	.icon {
		height: 100%
	}

}

//Instragram

#sbi_load{
	margin-top: 20px
	margin-bottom: 20px
	}

.sbi_load_btn{
	
	text-transform 	: 	uppercase!important
	font-size 		: 	22px!important
	display 		: 	inline-block!important
	border-radius 	: 	0!important
	padding 		: 	8px 30px!important
	background 		: 	transparent!important

	font-weight 	: 	600!important
	color 			: 	#ffffff!important
	&:hover {
		text-decoration: underline!important;
		box-shadow: 0 0 0 0 transparent!important;
	}
	}

