.social-slider {
    position relative
    margin: 0 -5px;
}

.social-slider__image {
    padding-left 5px
    padding-right 5px
}

.social-slider__image div {
    position relative
    padding-top: 100%
}

.social-slider__image img {
    position absolute
    top: 0
    left: 0
    height 100%
    width 100%
    object-fit: cover;
}

// Nav
.social-slider-nav {
    position absolute;
    width 100%
    left: 50%;
    bottom: 0
    transform: translate(-50%, 50%);
}

.social-slider-nav div {
    position relative;
    width 100%
    display flex
    justify-content: space-between;
}

.social-slider-nav__item {
    position relative
    display flex
    align-items center
    justify-content center
    background colorRed
    height 50px
    width: 50px;
    cursor pointer
    transition: 0.4s ease

    @media(min-width: 768px) {
        height 70px
        width 70px
    }
}

.social-slider-nav__item:hover {
    background-color: darken(#DD321C, 20%);
}


.social-slider-nav__item::before {
    content: "";
    height 15px
    width 15px
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg) translateX(-2px);
}


.social-slider-nav__item--prev::before {
    transform: rotate(-135deg) translateX(-2px);
}

// Base slick style
.slick-slide {
    float: left
    outline: none;
}

.slick-slide:focus {
    outline: none
}

.slick-list {
    width 100%
    overflow: hidden;
}