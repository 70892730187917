footer {
	overflow: hidden;
	padding: 80px 20px;
	background-color #000;

	@media(min-width: 768px) {
		padding: 80px 0;
	}
}

// Upper footer area
.footer-upper {
	position relative
	display flex
	flex-wrap: wrap
	margin: -30px -60px 60px -60px;

	@media(min-width: 768px) {
		margin-bottom 75px
	}
}

.footer-upper::before {
	position absolute
	content: "";
	display none
	top: 0
	right 0
	height 150px
	width 110px
	transform: rotate(-5deg) translateX(-40px);
	background-size contain
	background-repeat no-repeat
	background-position right top
	background-image: url('../images/stag-logo.svg')

	@media(min-width: 1024px) {
		display block
	}
}

.footer-upper__contact,
.footer-upper__menu,
.footer-upper__opening {
	position relative
	display flex
	flex-direction: column
	align-items flex-start
	margin-top 30px
	padding: 0 60px;
	z-index 2;
}

.footer-upper__contact .logo {
	width: 165px;
	margin-bottom 25px
}

.footer-upper__contact a {
	color: #fff;
	text-decoration none
	margin-bottom 5px
}

.footer-upper__contact .social-list--footer {
	margin-top 25px
}

.social-list--footer img {
	filter: grayscale(1)
}

.footer-upper__menu ul {
	padding-left 0
	margin-bottom 0
	list-style-type none
}

.footer-upper__menu li:not(:last-of-type) {
	margin-bottom 10px
}

.footer-upper__menu a {
	color: #fff;
	text-transform uppercase
	text-decoration none
}

.footer-upper__opening {
	text-transform: uppercase;
	color: #ffffff;
}

.footer-upper__opening span:first-child {
	margin-bottom 10px
}

// Signup form area
.footer-signup {
	overflow: hidden
	background: colorRed url('../images/cls/red-wood.jpg') top left repeat
}

.signup-area {
	display flex
	flex-wrap: wrap;	
	margin: 0 -30px;
}

.signup-area__form {
	width 100%
}

.signup-area__image {

	width: 500px;
	margin: auto;
}

.signup-area__form,
.signup-area__image {
	

	
	padding: 30px;


	@media(min-width: 1024px) {
		width 50%
	}
}


.signup-area__form h2 {
	margin-bottom: 15px;
}

.signup-area__image img {
	width 100%;
	height auto
}

// Family logo group
.footer-logo-group {
	display flex
	flex-wrap: wrap
	align-items center
	justify-content center
	margin: -40px -15px 60px -15px;

	@media(min-width: 768px) {
		margin: -40px -25px 60px -25px;
	}
}

.footer-logo-group__item {
	object-fit: contain;
	max-width: 125px;
	max-height: 50px;
	margin: 40px 15px 0 15px;

	@media(min-width: 768px) {
		max-width: 140px;
		max-height: 70px;
		margin: 40px 25px 0 25px;
	}
}


// Family accred group
.footer-accred-group {
	display flex
	flex-wrap: wrap
	align-items center
	justify-content center
	margin: -40px -25px 80px -25px;
}

.footer-accred-group__item,
.footer-accred-group__text {
	margin: 40px 25px 0 25px;
}

.footer-accred-group__item {
	object-fit: contain;
	max-width: 125px;
	max-height: 50px;

	@media(min-width: 768px) {
		max-width: 140px;
		max-height: 70px;
	}
}

.footer-accred-group__text {
	text-transform uppercase
	font-weight: 500;
	text-align center;
}


// Sign off
.footer-sign-off {
	text-align center
}

.footer-sign-off a {
	color: #ffffff;
	text-decoration none
}

.footer-sign-off a:hover {
	text-decoration underline
}