.generic-page {
  font-weight: 400
  padding: 40px 15px
  font-family: agendaFont
}

.generic-page h1,
.generic-page h2,
.generic-page h3,
.generic-page h4,
.generic-page h5,
.generic-page h6 {
  margin: 0 0 25px 0
  padding: 0
}

.generic-page p {
  margin-bottom: 15px
}

.generic-page h1 {
  font-size: 30px
}

.generic-page h2 {
  font-size: 24px

}

.generic-page ul,
.generic-page ol {
  margin: 0 0 5px 0
  padding: 0 0 0 15px
}

.generic-page li {

}

.generic-page a {
  font-weight: 700
  color: white
  text-decoration: none
}