body {
	position: relative;

	&::before {
		position: fixed;
        content: "";
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background: rgba(0,0,0,0.6);
        z-index: 998;
        opacity: 0; 
        pointer-events: none;
        transition: opacity 0.4s .4s ease;
	}
}

.c-popup {
    position fixed
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    opacity: 0; 
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.4s ease;

    &--image-only {
        .c-popup__inner {
            border: none;
            padding: 0;
        }

        img {
            display block
        }
    }

    &__inner {
        position: relative;
        max-height: 85vh;
        max-width: 90%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        font-weight: 700
		font-family: agendaFont
        text-align: center;
        background-color: #19a6b8;
        border: 10px solid #ffffff;
        padding: 40px 20px;

        @media(min-width: 768px) {
            max-width: 600px;
            padding: 40px;
        }
        
        @media(min-width: 1024px) {
            max-width: 700px;
            padding: 60px;
        }
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 15px;
        width: 15px;
        cursor: pointer;

        @media(min-width: 768px) { 
            top: 30px;
            right: 30px;
        }

        svg {
            display: block;
        }
    }

    .c-btn {
        display: inline-block;
        font-size: 22px;
        text-transform: uppercase;
        text-decoration: none;
        background-color: colorRed;
        transition: 0.4s ease;
        padding: 15px 30px;
        margin-top: 10px;
        color: #ffffff;

        @media(min-width: 768px) {
            font-size: 30px;
        }

        &:hover {
            background-color: darken(#DD321C, 20%);
        }
    }
}

body.popup--active {
    &::before {
        opacity: 100;
        pointer-events: all;
        transition: opacity 0.4s ease;
    }

    .c-popup {
        opacity: 100;
        pointer-events: all;
        transition: opacity 0.4s .4s ease;
    }
}

.cls-popup-header,
.cls-popup-body {
    // padding: 20px 10px;

    @media(min-width: 768px) {
        // padding: 40px;
    }
}

.cls-popup-header {
    margin-bottom 30px

    h1 {
        @media(max-width: 767px) {
            font-size: 28px;
            line-height: 30px;
        }

        @media(min-width: 1024px) {
            font-size: 62px;
            line-height: 62px;
        }
    }

    img {
        display: block;
        margin-top 30px;
    }
}

.cls-popup-body {
    font-size: 22px;
    line-height: 26px
    font-family: agendaCondensedFont;
    // background: #19a6b8 url(../images/cls/blue-wood.jpg) top left repeat;

    @media(min-width: 768px) {   
        font-size: 32px;
        line-height: 36px;
    }

    h2 {
        font-size: 28px;
        margin: 0px 0 25px 0;
        color: #000000;

        @media(min-width: 768px) {
            font-size: 34px;
        }
    }
}
