.sr-only {
	position: absolute
	width: 1px
	height: 1px
	padding: 0
	margin: -1px
	overflow: hidden
	clip: rect(0, 0, 0, 0)
	border: 0
}

.home__logo-container {
	position: relative
	max-width: 780px
	width: 100%
	height: auto
	@media ( max-width: 1500px ) {
		max-width: 540px
	}
	@media ( max-width: 1200px ) {
		max-width: 460px
	}
	@media ( max-width: 1000px ) {
		max-width: 400px
	}
}

.home__logo {
	position: relative
	max-width: 780px
	width: 100%
	height: auto
	z-index: 2
	@media ( max-width: 1500px ) {
		max-width: 540px
	}
	@media ( max-width: 1200px ) {
		max-width: 460px
	}
	@media ( max-width: 1000px ) {
		max-width: 400px
	}
}


.home__logo-shadow {
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	width: 100%
	z-index: 1
}

.logo_container {
	position: relative;
	height: calc(100% - 138px);
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 759px) {
		height: calc(100% + 56px);
	}
}

.page-title {
	background-color: colorRed
	color: white
	font-family: agendaFont
	margin: 0
	padding: 0 15px 40px 15px
	font-weight: 700
	font-size: 50px
	@media ( max-width: 759px ) {
		padding: 0 15px 20px 15px
		font-size: 30px
	}
}

.radial {
	position: relative
	text-align: center
}

.radial--about,
.radial--drinks,
.radial--contact,
.radial--tiki,
.radial--social {
	position: relative
}

.radial--about:before,
.radial--drinks:before,
.radial--contact:before,
.radial--tiki:before,
.radial--social:before  {
	content: ''
	background-size: contain
	position: absolute
	top: 50%
	left: 50%
	transform: translateX(-50%) translateY(-50%)
	@media ( max-width: 1100px ) {
		transform: translateX(-50%) translateY(-50%) scale(0.8)
	}
	@media ( max-width: 900px ) {
		transform: translateX(-50%) translateY(-50%) scale(0.6)
	}
	@media ( max-width: 450px ) {
		transform: translateX(-50%) translateY(-50%) scale(0.4)
	}
}

.radial--about:before {
	background: url('../images/cls/about.svg') center no-repeat
	height: 320px
	width: 220px
}

.radial--drinks:before {
	background: url('../images/cls/drinks.svg') center no-repeat
	width: 240px
	height: 300px
}

.radial--contact:before {
	background: url('../images/hearts.png') center /contain no-repeat
	width: 260px;
	height: 260px;
}

.radial--tiki:before {
	background: url('../images/family-logos/tiki.png') center / contain no-repeat
	width: 225px
	height: 148px
}

.radial--social:before {
	background: url('../images/cls/social.svg') center no-repeat
	width: 255px
	height: 225px
}


.social-list {
	list-style: none
	padding: 0
	line-height: 1
	font-size: 20px
	.icon {
		display: inline-block
	}
}
.social-list li {
	margin: 0 15px
	padding: 0
	display: inline-block
	&:first-child {
		margin-left: 0
	}
}

/*------------------------------*\
	Opening Times
\*------------------------------*/
.opening-times {
	list-style: none
	padding: 0
}
.opening-times li {
	margin: 5px 0 0
	padding: 0
	&:first-child {
		margin-top: 0
	}

	@media(max-width: 767px) {
		text-align: center;
	}
}
.opening-times span {
	display: inline-block
	width: 6em
	text-align: left
	// width: 100%
	@media ( max-width: 660px ) {
		text-align: center
	}
}


/*------------------------------*\
	Menu
\*------------------------------*/
div.menu {
	text-align: center
	margin-bottom: 60px
	img {
		display: block
		margin: 0 auto
	}
}
.menu-download {
	font-size: 48px
	text-transform: uppercase
	display: inline-block
	padding-bottom: 8px
	border-bottom: 3px solid white
	text-decoration: none
	color: inherit
	line-height: 1
	margin-top: 40px
	font-family: agendaFont
	font-weight: 700
}


@keyframes pageGrowImage {
	from {transform: scale(0);}
    to {transform: scale(1);}
}

.overlay {
	position fixed
	left 0
	z-index 999999999
	padding 50px
	top 0
	width 100%
	height 100%
	background rgba(#000, 50%)
	display flex
	align-items center
	justify-content center
	opacity 1
	transition 0.4s ease-in-out

	// @media(max-width 768px) {
	// 	display none
	// }

	a {
		display flex
		align-items center
		justify-content center
		// animation: pageGrowImage 0.8s

		@media(max-width 768px) {
			height: 50%;
			animation: none;
		}
			
	}

	img {
		max-width 100%;
		max-height: 100%;
		width: auto!important;
	}

	&.closed {
		opacity 0
		transition 0.4s ease-in-out
		z-index -1

		img {
			width 500px
			height 500px
			transition 0.4s ease-in-out
		}
	}
}


.four-o-four {
	padding-top: 80px
	padding-bottom: 80px
	text-align: center
}
