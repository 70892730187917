div.gallery {
	position: relative
	&:before,
	&:after {
		content: " "
		display: table
	}
	&:after {
		clear: both
	}
}

.gallery__sizer {
	width: 25%
}

.gallery-item {
	float: left
	display: block
	position: relative
	background-size: cover
	background-position: center center
	&:before {
		content: ""
		display: block
		width: 100%
	}
}
.gallery-item > div {
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
}
.gallery-item--1 {
	width: 25%
	&:before {
		padding-top: 100%
	}
	@media (max-width: 950px) {
		width: 50%
	}
}
.gallery-item--2 {
	width: 50%
	&:before {
		padding-top: 50%
	}
	@media (max-width: 950px) {
		width: 100%
	}
}
.gallery-item--3 {
	width: 25%
	&:before {
		padding-top: 200%
	}
	@media (max-width: 950px) {
		width: 50%
	}
}