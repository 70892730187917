.header {
	text-align: center
}
body.home .header {
	min-height: 300px
	height: 50vh
	@media (max-width: 759px) {
		//min-height: 100vh
	}
}
	.home-above-fold {
		height: 50vh
	}
body:not(.home) .header {
	@media (max-width: 759px) {
		padding-top: 60px
		background-color: colorRed
	}
}

.header__cat {
	position: absolute
	z-index: 900
	top: 25px
	left: 50%
	width: 66px
	transform: translateX(-50%)
	opacity: 0
	transition: opacity 0.3s
	img {
		display: block
		width: 100%
	}
	@media ( max-width: 759px ) {
		width: 26px
	}
}


.header__cat {
	@media (max-width: 759px) {
		top: 8px
		position: fixed
	}
}

.header__cat--active {
	opacity: 1
}

@media (min-width: 760px) {
	.header__cat--main {
		opacity: 1 !important
	}
}

.socialmedia--icon {
	fill #fff
	transition 0.4s ease-in-out
	cursor pointer
}

.like-fb {
	position: absolute
	top: 50%
	left: 20px
	transform: translateY(-50%)
	text-transform: uppercase
	color: white
	text-decoration: none
	font-weight: 700
	font-size: 34px
	background-image: url('../images/cls/like.svg')
	background-size: contain
	background-repeat: no-repeat
	background-position: center
	padding: 20px 10px 0 10px
	@media ( max-width: 900px ) {
		font-size: 26px
	}
	@media ( max-width: 759px ) {
		display: none
	}
}

.like-fb-mob {
	text-transform: uppercase
	color: white
	text-decoration: none
	display: block
	font-weight: 700
	font-family: agendaFont
	font-size: 17px
	position: absolute
	top: 50%
	right: 15px
	transform: translateY(-50%)
	@media ( min-width: 760px ) {
		display: none
	}
}


.like-fb-mob span {
	vertical-align: middle
}

.like-fb-mob span:before {
	transform: translate(0,0) translateY(15%) !important
}
