.nav-main {
	text-transform: uppercase
	background-color: colorRed
	z-index: 6
}
.nav-main ul {
	list-style: none
	margin: 0
	padding: 0
}
.nav-main li {
	margin: 0
	padding: 0
}
.nav-main a {
	text-decoration: none
	font-family: agendaCondensedFont
	font-weight: 700
	font-size: 20px
	@media ( max-width: 1040px ) {
		font-size: 15px
	}
}

.current-menu-item a {
	color: white
}

.nav-main li:not(.current-menu-item) a {
	color: #DDB594
}

.nav-main li:not(.current-menu-item) a:hover {
	color: white
}

/*------------------------------*\
	Desktop
\*------------------------------*/
@media (min-width: 760px) {
	.nav-main {
		position: relative
		padding: 50px 0
	}
	.nav-main ul {
		font-size: 0
	}
	.nav-main li {
		display: inline-block
		font-size: 15px
		width: 100px
		&:first-child {
			margin-left: 0
		}
		&:nth-child(4) {
			margin-left: 208px
		}
	}
	.nav-main a {
		display: inline-block
		padding-bottom: 4px
	}
	.nav-main .current-menu-item a {
		border-bottom: 4px solid white
	}
}
@media (min-width: 760px) and (max-width: 1040px) {
	.nav-main li:nth-child(4) {
		margin-left: 158px
	}
	.nav-main li {
		width: 72px
	}
}

/*------------------------------*\
	Mobile
\*------------------------------*/
.mobile-top {
	display: block
	visibility: hidden
}
@media (max-width: 759px) {
	.mobile-top {
		display: block
		visibility: visible
		height: 56px
		position: fixed
		top: 0
		left: 0
		width: 100%
		z-index: 100
		background-color: colorRed
	}
	.nav-main__toggle {
		display: block
		position: absolute
		top: 0
		left: 0
		width: calc(100% - 60px)
		height: 56px
		transition: transform 0.3s
		z-index: 1
	}
	.nav-main__toggle a {
		display: block
		width: 20px
		height: 16px
		margin-top: 20px
		margin-left: 20px
		position: relative
		span:first-child,
		&:before,
		&:after {
			display: block
			width: 20px
			height: 2px
			background: white
			position: absolute
			left: 0
			transition: all 0.3s
		}
		span:first-child {
			top: 7px
		}
		&:before,
		&:after {
			content: ""
		}
		&:before {
			top: 0
		}
		&:after {
			bottom: 0
		}
	}
	.nav-main {
		position: fixed
		top: 0
		bottom: 0
		left: 0
		z-index: 1000
		text-align: left
		width: 80%
		width: calc(100% - 60px)
		padding-top: 40px
		padding-bottom: 40px
		font-size: 17px
		transform: translateX(-100%)
		transition: transform 0.3s
	}
	.nav-main a {
		display: block
		padding: 10px 40px
	}

	.nav-main--active {
		transform: none
	}
	.mobile-top--active {
		.nav-main__toggle {
			transform: translateX(100%)
			z-index: 2
			background-color: colorRed
			span:first-child {
				opacity: 0
			}
			> a {
				&:before {
					transform: translateY(7px) rotate(45deg)
				}
				&:after {
					transform: translateY(-7px) rotate(-45deg)
				}
			}
		}
	}
}
