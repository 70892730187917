@font-face {
	font-family: 'agenda';
	src: url('../fonts/agenda-bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/agenda-bold.woff2') format('woff2'),
		 url('../fonts/agenda-bold.woff') format('woff'),
		 url('../fonts/agenda-bold.ttf') format('truetype'),
		 url('../fonts/agenda-bold.svg#agenda-bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'agenda';
	src: url('../fonts/agenda-light.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/agenda-light.woff2') format('woff2'),
		 url('../fonts/agenda-light.woff') format('woff'),
		 url('../fonts/agenda-light.ttf') format('truetype'),
		 url('../fonts/agenda-light.svg#agenda-light') format('svg');
	font-weight: 300;
	font-style: normal;
}


@font-face {
	font-family: 'agenda-boldcondensed';
	src: url('../fonts/agenda-boldcondensed.eot?#iefix') format('embedded-opentype'),
					url('../fonts/agenda-boldcondensed.woff2') format('woff2'),
					url('../fonts/agenda-boldcondensed.woff') format('woff'),
					url('../fonts/agenda-boldcondensed.ttf') format('truetype'),
					url('../fonts/agenda-boldcondensed.svg#agenda-boldcondensed') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'mec-icons';
	src: url('../fonts/mec-icons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/mec-icons.woff2') format('woff2'),
		url('../fonts/mec-icons.woff') format('woff'),
		url('../fonts/mec-icons.ttf') format('truetype'),
		url('../fonts/mec-icons.svg#mec-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}




.icon:before {
	display: inline-block
	font: normal normal normal 14px/1 'mec-icons'
	font-size: inherit
	text-rendering: auto
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	transform: translate(0, 0)
	width: 1em
	height: 1em
}

.icon--open {
	background-image: url('../images/icon-open.png');
	height 30px
	width 30px
	display block
	margin auto
	background-size contain
	background-position center center
	background-repeat no-repeat
}

.icon--twitter:before { content: "\e000"; }
.icon--facebook:before { content: "\e001"; }
.icon--play:before { content: "\e002"; }
.icon--pause:before { content: "\e003"; }
.icon--stop:before { content: "\e004"; }
.icon--next:before { content: "\e005"; }
.icon--previous:before { content: "\e006"; }
.icon--marker:before { content: "\e007"; }
.icon--arrow-down:before { content: "\e008"; }
.icon--instagram:before { content: "\e009"; }
