select::-ms-expand {
  display: none;
}

.dmn-form {
  border: none !important;
  margin-bottom: 40px !important;
  font-size: 22px !important;
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0 15px !important;
  color: #fff !important;
  width: 100% !important;
}

.dmn-form .dmn-overlay {
  background: none !important;
  z-index: -1 !important;
}

.dmn-form h1 {
  display: none !important;
}
 

.dmn-form label {
  display: inline-block !important;
  font-size: 25px !important;
  max-width: 190px !important;
  width: 100% !important;
  text-transform: uppercase !important;
  vertical-align: top !important;
  line-height: 40px !important;
  top: 0 !important;
  left: 0 !important;
  font-family: agenda,Helvetica,Arial,sans-serif!important;
  font-weight: 700!important;
}

.dmn-type-container,
.dmn-num-people-container,
.dmn-date-container,
.dmn-time-container,
.dmn-duration-container
{
  font-size: 0 !important;
  margin-bottom: 30px !important;
  position: relative !important;
  display: flex !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #fff !important;
  min-height: 41px !important;
}


#dmn-date,
#dmn-num-people {
  width: calc(100% - 220px);
  display: inline-block;
  height: 40px;
  color: #fff;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  font-size: 20px;
  font-family: Helvetica,Arial,sans-serif;
  outline: 0;
  box-shadow: none;
  padding: 0 !important;
  //display: inline-block;
  //vertical-align: top;
  //font-size: 22px;
  //width: calc(100% - 140px);
}

.partner-form-modal .modal-header h3 {
    color: #1fb8ca!important;
}

.header-row.header-row-partner .top-border {
    background-color: #1fb8ca;
}

.dmn-date-container .datepicker {
  width: calc(100% - 220px) !important;
}


.dmn-date-container .datepicker table {
  width: 100% !important;
  color: #1fb8ca !important;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 18px !important;
}

.dmn-form .btn-link {
     color: #1fb8ca !important;
}

.dmn-date-container .datepicker button {

}

.dmn-date-container .datepicker button:disabled > span {
  color: #000 !important;
}


//.dmn-form select:not(#dmn-duration) {
.dmn-form select {
  width: calc(100% - 220px) !important;
  margin: 0 !important;
  background: none !important;
  border: 1px solid transparent !important;
  outline: none !important;
  box-sizing: border-box !important;
  -moz-appearance: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  font-size: 20px !important;
  font-family: Helvetica,Arial,sans-serif !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding: 0 !important;
  line-height: 1 !important;
  height: 50px !important;
}



.dmn-form select:not(#dmn-duration) option {
  font-weight: normal !important;
  color: #000 !important;
}

.dmn-form select option {
  color: #000 !important;
}



.dmn-form select#dmn-duration {
  width: calc(100% - 220px) !important;
  margin: 0 !important;
  border: 1px solid transparent !important;
  outline: none !important;
  box-sizing: border-box !important;
  -moz-appearance: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  font-size: 20px !important;
  font-family: Helvetica,Arial,sans-serif !important;
  font-weight: 400 !important;
  padding: 0 !important;
  line-height: 1 !important;
}

.dmn-type-container:after,
.dmn-num-people-container:after,
.dmn-date-container:after,
.dmn-time-container:after,
.dmn-duration-container:after{
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -3px;
  z-index: 2;
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #fff transparent transparent transparent;
  -webkit-transform: rotate(360deg);
}

.booking-form button.submit {
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
    border: 0!important;
    border-bottom: 2px solid #fff!important;
    border-radius: 0!important;
    background: none!important;
    line-height: 1!important;
    color: #fff!important;
    text-transform: uppercase!important;
    font-size: 30px!important;
    padding: 0 0 4px!important;
    font-weight: 700!important;
    font-family: agenda,Helvetica,Arial,sans-serif!important;
    display: inline-block!important;
    width: auto!important;
    position: relative!important;
    left: 50%!important;
    transform: translateX(-50%)!important;
}

.booking-form button.submit:hover {
	    border-width: 3px;
}

.booking-form .main-inputs {
    position: relative!important;
}

.powered-by {
  display: none !important;
}

@media ( max-width: 820px ) {
  .dmn-form {
      width calc(100% - 30px) !important;
  }

  .main-inputs > div {
    display: flex !important;
    flex-direction: column !important;
  }

//   .dmn-form select:not(#dmn-duration) {
//     width: 100% !important;
//   }

  .dmn-type-container:after,
  .dmn-num-people-container:after,
  .dmn-date-container:after,
  .dmn-time-container:after {
    top: 75% !important;
  }

//   .dmn-form select#dmn-duration {
//     width: 100% !important;
//   }
//   #dmn-date {
//     width: 100%;
//   }
}

@media ( max-width: 380px ) {
  #dmn-type {

  }
}

@media ( max-width: 740px ) {
  .dmn-date-container .datepicker {
    width: 100% !important;
  }
  .bookingsFirstP {
    width: 100% !important;
    padding: 0 15px 0 15px !important;
    margin-left: 0 !important;
  }

  .bookingsSecondP {
    width: 100% !important;
    padding: 30px 15px 50px 15px !important;
    margin-left: 0 !important;
  }
}

  @media ( max-width: 1200px ) {
    .bookingsFirstP,
    .bookingsSecondP {
      //width: 100% !important;
      //padding: 0 15px 0 15px !important;
      //margin-left: 0 !important;
      margin: 0 auto !important;
      max-width: 700px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100% !important;
    }

  }

@media ( max-width: 730px ) {
  .bookingsFirstP,
  .bookingsSecondP {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .bookingsFirstP {
    margin-bottom: 50px !important;
  }
}


.dmn-form select,
.dmn-form input,
.dmn-form select#dmn-duration {
  @media(max-width:568px) {
    width: 100%!important;
  }
}