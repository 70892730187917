.little-form {
	font-size: 22px
	max-width: 700px
	margin-left: auto
	margin-right: auto
	padding: 0 15px
}

.little-form__field {
	font-size: 0
	margin-bottom: 30px
	position: relative
	border-bottom: 2px solid white
	min-height: 41px
}

.little-form__error {
	font-size: 15px
	margin-top: 6px
}

.little-form__label {
	display: inline-block
	font-size: 22px
	width: 80px
	vertical-align: top
	line-height: 40px
	//position: absolute
	top: 0
	left: 0
	font-family: agendaFont
	font-weight: 700
}

.little-form__label span {
	font-size: 75%
}

.little-form__input {
	display: inline-block
	vertical-align: top
	font-size: 22px
	width: calc(100% - 80px)
}
.little-form {
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%
		display: block
		height: 40px
		color: white
		background: none
		-webkit-appearance: none
		appearance: none
		border-radius: 0
		border: 0
		font-size: 20px
		font-family: Helvetica, Arial, sans-serif
		//padding-left: 140px
		&:focus {
			outline: none
		}
	}
	textarea {
		height: 200px
		padding-top: 6px
		padding-bottom: 12px
		resize: vertical
	}

	button {
		-webkit-appearance: none
		appearance: none
		border: 0
		border-bottom: 2px solid white
		border-radius: 0
		background: none
		line-height: 1
		color: white
		text-transform: uppercase
		font-size: 30px
		padding: 0 0 4px
		font-weight: 700
		font-family: agendaFont
		&:hover {
			border-width: 3px
		}
		&[disabled] {
			opacity: 0.5
		}
	}
}

.little-form__submit {
	border: 0
	text-align: center
}

.little-form__row {
	margin-left: -15px
	margin-right: -15px
	&:before,
	&:after {
		content: " "
		display: table
	}
	&:after {
		clear: both
	}
}
[class*="little-form__col"] {
	float: left
	padding-left: 15px
	padding-right: 15px
	width: 100%
}
.little-form__col-1-2 {
	width: 50%
	@media (max-width: 700px) {
		width: 100%
	}
}


.little-form__select {
	//padding-left: 140px
}

.little-form select {
	width: 100%
	color: black!important
}

.little-form select option {
	color: black!important
}


body.bookings .little-form {
	margin-bottom: 40px
}

.bookings-intro-text {
	width:100%
	text-align:left;
	padding: 40px 15px 50px 15px
	font-size: 22px;
	max-width: 700px
	margin: auto
	font-weight: 700
	font-family: agendaFont
}

.bookings-intro-text a {
	text-decoration: underline!important;
	color: white

	&:hover {
		text-decoration: none!important;
	}
}
