* {
	box-sizing: inherit
}

body {
	background: colorBg url('../images/cls/blue-wood.jpg') top left repeat
	color: colorText
	font-family: agendaFont
	font-size: fontSize
	line-height: lineHeight
	font-weight: fontWeight
}

img {
	max-width: 100%
}

p {
	margin: 0 0 25px
}

ul {
	margin: 0 0 25px
}

.container {
	max-width: containerWidth + gutterWidth
}

h1, .h1,
h2, .h2,
h3, .h3 {
	line-height: 1.1
	font-weight: 700
	text-transform: uppercase
	color: inherit
}

h1, .h1 {
	font-size: 62px
	margin: 0 0 50px
	padding: 0 20px
	span {
		font-weight: 700
		font-family: agendaFont
		font-size: 75px
		color: colorRed
		vertical-align: -0.02em
		@media (max-width: 700px) {
			font-size: 52px
		}
	}
	@media (max-width: 700px) {
		font-size: 43px
	}
}

h2, .h2 {
	font-size: 30px
	margin: 60px 0 30px
}

h3, .h3 {
	font-size: 22px
	margin: 0 0 30px
}


.bg-sim {
	position absolute
	top: 0;
	left: 0;
	height 100%
	width 100%
	object-fit: cover;	
}


.section-py--md {
	padding-top 60px
	padding-bottom 60px

	@media(min-width: 768px) {
		padding-top 80px
		padding-bottom 80px
	}
}