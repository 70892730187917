.contact a {
	color: inherit
	text-decoration: none
}

.contact .email {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.contact__col {
	display: inline-block
	width: 50%
	font-size: 21px
	padding-left: 20px
	vertical-align: middle
	@media (max-width: 660px) {
		width: 100%
		padding: 0 !important
		text-align: center !important
	}
}

.contact__col.opening-times {
	padding-left: 20px
}

.contact .leave-message {
	font-size: 40px;
	text-align: center
	margin-top: 60px;
	margin-bottom 50px
	@media ( max-width: 759px ) {
		font-size: 26px
		margin-top: 40px
		margin-bottom 0px
	}
}

h2.contact__col {
	text-align: right
	padding-left: 0
	padding-right: 20px
	margin-top: 5px
	@media ( max-width: 660px ) {
		margin-top: 0
	}
}

.contact__social {
	text-align: center
	font-size: 40px !important
}



.contact__family--horizontal {
	list-style: none
	margin: 0
	padding: 0
	font-size: 0
	text-align: center
	margin-bottom: 60px
}
.contact__family--horizontal li {
	margin: 0
	padding: 0
	width: 220px
	display: inline-block
	font-size: fontSize
	vertical-align: middle
	position: relative
}

div.contact .little-form {
	margin-top: 60px
}

.family-contact {
	@media ( max-width: 999px ) {
		display: none
	}
}



.contact-info-map-split {
	display flex
	flex-wrap: wrap;
	margin: -40px 0px;
	font-size: 16px;

	@media(min-width: 768px) {
		margin: -40px -30px;
	}
}


.contact-info-map-split__meta,
.contact-info-map-split__map {
	width 100%
	margin-top: 40px;
	padding-left: 20px;
	padding-right: 20px;


	@media(min-width: 768px) {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.contact-info-map-split__meta {
	display flex
	flex-direction: column;
	align-items: flex-start;

	@media(min-width: 1024px) {
		width 40%
	}
}

.contact-info-map-split__meta span {
	@extend .h3;
	display block
	margin-bottom 10px
}

.contact-info-map-split__meta a {
	text-decoration none;
	margin-bottom 10px;
}

.contact-info-map-split__meta a,
.contact-info-map-split__meta p {
	text-transform uppercase
}

.contact-info-map-split__meta img {
	margin-top 0px
}

.contact-info-map-split__map {
	@media(min-width: 1024px) {
		width 60%
	}
}

.contact-info-map-split__map {
	display flex
	flex-wrap: wrap
}

.contact-info-map-split__map h3 {
	margin-bottom 0
}

.contact-info-map-split__map p {
	text-transform uppercase
	margin-left auto
	margin-bottom 0
}

.contact-info-map-split p::before {
	margin-right 10px
}

.contact-info-map-split__map a {
	width 100%	
	margin-top 20px
}


.contact-info-map-split__map img {
	width 100%
}



.form-area {
	width 100%
	text-align center

	@media(min-width: 768px) {
		margin: 60px auto 0 auto;
		width 80%
	}
}




.social-list {
	display flex
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom 0
}

.social-list__item {
	display flex
	align-items center
	justify-content center
	border-radius: 100%;
	background #fff;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.social-list__item img {
	height 20px
	width 20px
}