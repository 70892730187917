.masonry {
	font-size: 30px
	line-height: 1.5
	&:before,
	&:after {
		content: ""
		display: table
	}
	&:after {
		clear: both
	}
}

.masonry-sizer {
	width: 33.3333333%
}

.masonry-item {
	display: block
	position: relative
	float: left
	background-position: center center
	background-size: cover
	&:before {
		display: block
		content: ""
		width: 100%
	}
}
.masonry-item > div {
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
}
.masonry-item > div > div {
	position: absolute
	top: 50%
	left: 0
	width: 100%
	transform: translateY(-50%)
	padding-left: 40px
	padding-right: 40px
	@media (max-width: 767px) {
		padding-left: 20px
		padding-right: 20px
	}
}

.masonry-item--1 {
	width: 33.3333333%
	&:before {
		padding-top: 100%
	}
	@media (max-width: 767px) {
		width: 50%
	}
}
.masonry-item--2 {
	width: 66.6666666%
	&:before {
		padding-top: 50%
	}
	@media (max-width: 767px) {
		width: 50%
		&:before {
			padding-top: 100%
		}
	}
}
.masonry-item--3 {
	width: 40%
	&:before {
		padding-top: 75%
	}
}
.masonry-item--4 {
	width: 60%
	&:before {
		padding-top: 50%
	}
}

.masonry-item--hidden-mob {
	display:block;
	@media (max-width: 767px) {
		display: none;
	}	
}

.masonry-bg-red {
	background: url('../images/cls/red-wood.jpg') top left repeat
}

.masonry-bg-yellow {
	background: url('../images/cls/yellow-wood.jpg') top left repeat
}


/*
	First color is Background Color, 2nd is Border Color
 */

.masonry-item-yellow-frame {
	background: url('../images/borders/yellow.png');
}

.masonry-item-red-yellow {
	// background-color: colorRed
	// border: 6px solid colorYellow
	background: colorRed center / cover no-repeat url('../images/borders/yellow.png');
}
.masonry-item-blue-red {
	// background-color: colorBlue
	// border: 6px solid colorRed
	background: colorBlue center / cover no-repeat url('../images/borders/red.png');
}
.masonry-item-yellow-blue {
	// background-color: colorYellow
	// border: 6px solid colorBlue
	background: colorYellow center / cover no-repeat url('../images/borders/blue.png');
}
.masonry-item-red-blue {
	// background-color: colorRed
	// border: 6px solid colorBlue
	background: colorRed center / cover no-repeat url('../images/borders/blue.png');
}
.masonry-item-blue-yellow {
	// background-color: colorBlue
	// border: 6px solid colorYellow
	background: colorBlue center / cover no-repeat url('../images/borders/yellow.png');
}


.masonry-item--cream { 
	background: colorCream url('../images/white.jpg') top left repeat
	color: black
}

.masonry-item .radial img {
	width: 100%
	max-height: 120px
	@media (max-width: 767px) {
		max-height: 52px
	}
}

.masonry-item .radial h2 {
	font-family: agendaFont
	font-size: 74px
	color: white
	margin: 0
	@media ( max-width: 1400px ) {
		font-size: 56px
	}
	@media ( max-width: 1100px ) {
		font-size: 36px
	}
	@media ( max-width: 540px ) {
		font-size: 28px
	}
	@media ( max-width: 440px ) {
		font-size: 22px
	}
}


.masonry-item .radial {
	width: 100%
	max-width: 370px
	margin-left: auto
	margin-right: auto
	@media ( max-width: 400px ) {
		max-width: 300px
	}
}


/*------------------------------*\
	Row
\*------------------------------*/
.masonry-row {
	display: table
	width: 100%
	@media (max-width: 950px) {
		display: block
	}
}
.masonry-row .masonry-item {
	display: table-cell
	vertical-align: top
	float: none
	@media (max-width: 1200px) {
		width: 50% !important
	}
	@media (max-width: 950px) {
		width: 100% !important
		display: block
	}
}
.masonry-row .masonry-item:before {
	content: none
}

.masonry-item p {
	margin: 0
	padding: 80px 60px
	font-size: 26px
	font-weight: 700
	@media (max-width: 600px) {
		padding: 50px 40px
		font-size: 20px
	}
	@media (min-width: 1400px ) {
		padding: 120px 60px
	}
	@media (min-width: 1800px ) {
		padding: 160px 100px
	}
}


.masonry-row .masonry-item[style*="background-image"]:not(.masonry-item--single-image-mob)  {
	@media (max-width: 950px) {
		height: 300px
	}
}


.masonry-row .masonry-item--single-image-mob  {
	height: unset;
	@media (max-width: 951px) {
		height: 550px;
		max-height: 100vw;
	}

	@media (max-width: 550px) {
		height: 450px;
	}
}

.bg-align-bottom-mob  {
	@media (max-width: 951px) {
		background-position: bottom;
	}
}

@media (max-width: 767px) {
	.pull-left-mobile {
		position: relative
		right: 50%
	}
	.pull-right-mobile {
		position: relative
		left: 50%
	}
	.masonry-hide-mobile {
		display: none
	}
}




.masonry-item--pizza img {
	max-width: 90%
	max-height: 90%
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
}

.masonry-item--pizza {
	@media (max-width: 950px) {
		height: 400px
	}
}

.masonry-item--pizza:before {
	@media (max-width: 767px) {
		content: '' !important
		display: block !important
		padding-top: 100% !important
	}
}



.masonry-item.b-lazy {
	opacity: 0
	transition: opacity 0.3s
	&.b-loaded {
		opacity: 1
	}
}


//Video
.video {
	position: fixed
	width: 100%
	height: 100%
	pointer-events: all;
	background-color: rgba(0 0, 0, 0.7);
	z-index: 1000
	top: 0px
	left: 0px!important
	bottom: 0px
	display: flex;
	flex-direction: column
	align-items: center;
	justify-content: center;

	&.hide {
		display: none
	}
}


.hero__close {
	-webkit-appearance: none
	appearance: none
	padding: 0
	background: colorRed
	border: 0
	border-radius: 0
	display: block
	width: 36px //24
	height: 36px //22
	position: absolute
	z-index: 9
	top: 15px
	right: 15px
}
.hero__close:before,
.hero__close:after {
	content: ""
	position: absolute
	display: block
	width: 24px
	height: 4px
	background: white
	left: 6px
	top: 7px
}
.hero__close:before {
	transform: translateY(9px) rotate(45deg)
}
.hero__close:after {
	top: 25px
	transform: translateY(-9px) rotate(-45deg)
}

.playBtn {
	cursor: pointer
	@media ( max-width: 767px ) {
		width: 100% !important
	}
}

.playBtn:after {
	content: ''
	background-image: url('../images/cls/play.gif')
	background-repeat: no-repeat
	background-position: center
	background-size: contain
	position: absolute
	width: 40%
	height: 40%
	max-width: 200px
	max-height: 200px
	top: 50%
	left: 50%
	transform: translateX(-50%) translateY(-50%)
	pointer-events: none
	//@media(max-width: 767px) {
	//	width: 100%
	//	height:200px
	//}
}
